import React from "react"
import "../stylesheets/components/footer.css"

const Footer = ({color, marginTop}) => {
    return (
        <div id="footer" style={{marginTop: `${marginTop}`}} className={`mob ${color === "white" ? "footerWhite" : "footerBlack"}`}>
          <p>
            Baton Rouge, LA<br/>
            (225) 384-6682<br/>
            contact@dhlabs.io
          </p>
        </div>
    )

}

export default Footer
