import React from "react"
import Layout from "../components/layout"
import "../stylesheets/pages/about.css"
import ImgW from "../../public/Walter.jpeg"
import ImgI from "../../public/Ian.jpeg"
import ImgR from "../../public/Remy.png"
import ImgM from "../../public/Michael.jpeg"
// import ImgS from "../../public/saxquatch.png"
import Footer from "../components/footer.js"


export default function About() {
  return (
    <Layout>
      <div className="intro mob">
        <p><span className="ourMission mob"><strong>Our mission</strong></span>
        &nbsp;is to help early-stage startups avoid prolonged
        development time, we want to get you up and running quickly
        because <strong>your product should be in the hands of your customers
        giving you feedback or in front of investors, not in the lab.</strong></p>

        <p><br/>At Dark Horse Labs we're passionate about technology and
        entrepreneurship. We want to see your ideas come to life, and
        we know that a simple beautiful product is all you need to get
        started.</p>
      </div>

      <div className="titleAbout mob">Meet the Team.</div>

      <div className="rowA mob">
        <div className="columnA mob">
          <img id="profile" className="mob" src={ImgW} alt="pic" />
          <div className="name mob">Walter Scott</div>
          <div className="bio mob">Co-Founder</div>
        </div>
        <div className="columnA mob">
          <img id="profile" className="mob" src={ImgI} alt="pic" />
          <div className="name mob">Ian Andrepont</div>
          <div className="bio mob">Co-Founder</div>
        </div>
        <div className="columnA mob">
          <img id="profile" className="mob" src={ImgR} alt="pic" />
          <div className="name mob">Remy McConnell</div>
          <div className="bio mob">Co-Founder & Software Engineer</div>
        </div>
        <div className="columnA mob">
          <img id="profile" className="mob" src={ImgM} alt="pic" />
          <div className="name mob">Michael Sheppard</div>
          <div className="bio mob">Co-Founder & Software Engineer</div>
        </div>
      </div>

      <Footer color="white"/>
    </Layout>
  )
}
